import * as Yup from 'yup';
import { ICohabitationAgreementValues } from '#/types/cohabitationAgreeement';
import { AuthUser } from '#/types/auth';

export const schemaDefaults = (
  user: AuthUser,
  activeStep: number,
  initialValues?: ICohabitationAgreementValues
) => ({
  step: activeStep,
  partner: {
    type: 'partner',
    first_name: initialValues?.contract_parties_ca[0]?.first_name ?? '',
    last_name: initialValues?.contract_parties_ca[0]?.last_name ?? '',
    birthday: initialValues?.contract_parties_ca[0]?.birthday ?? null,
    place_of_citizenship:
      initialValues?.contract_parties_ca[0]?.place_of_citizenship ?? '',
    email: initialValues?.contract_parties_ca[0]?.email ?? '',
    address: {
      city: initialValues?.partner?.address?.city ?? '',
      country: initialValues?.partner?.address?.country ?? 'CH',
      house_number: initialValues?.partner?.address?.house_number ?? '',
      street: initialValues?.partner?.address?.street ?? '',
      zip_code: initialValues?.partner?.address?.zip_code ?? '',
    },
  },
  self: {
    type: 'self',
    first_name:
      (initialValues?.contract_parties_ca[1]?.first_name || user?.first_name) ??
      '',
    last_name:
      (initialValues?.contract_parties_ca[1]?.last_name || user?.last_name) ??
      '',
    birthday:
      (initialValues?.contract_parties_ca[1]?.birthday ||
        user?.date_of_birth) ??
      null,
    place_of_citizenship:
      initialValues?.contract_parties_ca[1]?.place_of_citizenship ?? '',
    address: {
      city:
        (initialValues?.contract_parties_ca[1]?.address.city ||
          user?.address?.city) ??
        '',
      country:
        (initialValues?.contract_parties_ca[1]?.address.country ||
          user?.address?.country) ??
        'CH',
      house_number:
        (initialValues?.contract_parties_ca[1]?.address.house_number ||
          user?.address?.house_number) ??
        '',
      street:
        (initialValues?.contract_parties_ca[1]?.address.street ||
          user?.address?.street) ??
        '',
      zip_code:
        (initialValues?.contract_parties_ca[1]?.address.zip_code ||
          user?.address?.zip_code) ??
        '',
    },
  },
  meet_year: initialValues?.meet_year ?? null,
  date_of_moving_in: initialValues?.date_of_moving_in ?? null,
  situation: initialValues?.situation ?? '',
  joint_household_account_bank:
    initialValues?.joint_household_account_bank ?? '',
  equal_payment_amount: initialValues?.equal_payment_amount || null,
  equal_payment_frequency: initialValues?.equal_payment_frequency ?? '',
  date_of_first_payment: initialValues?.date_of_first_payment ?? null,
  payment_ratio_per_party: initialValues?.payment_ratio_per_party ?? null,
  compensation_payment_cadence:
    initialValues?.compensation_payment_cadence ?? '',
  compensation_share: initialValues?.compensation_share ?? null,
  expenses_to_be_covered: initialValues?.expenses_to_be_covered ?? [
    'rent',
    'radioTV',
    'groceries',
    'cleaning',
    'additional',
  ],
  current_rental_agreement_name:
    initialValues?.current_rental_agreement_name ?? '',
  change_contract_plans: initialValues?.change_contract_plans ?? null,
  monthly_transfer_for_free_disposal:
    initialValues?.monthly_transfer_for_free_disposal ?? '',
  free_disposal_amount: initialValues?.free_disposal_amount ?? null,
  household_and_children_responsibility:
    initialValues?.household_and_children_responsibility ?? '',
  who_works_full_time: initialValues?.who_works_full_time ?? '',
  workload_distribution: initialValues?.workload_distribution ?? null,
  account_balance_in_case_of_separation:
    initialValues?.account_balance_in_case_of_separation ?? null,
});

export const stepOneValidation = Yup.object().shape({
  partner: Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),
    email: Yup.string()
      .email('validations.user.email')
      .required('validations.user.email'),
    birthday: Yup.date()
      .typeError('validations.invalid_date')
      .required('validations.user.date_of_birth.required')
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        'validations.user.date_of_birth.max'
      ),
    place_of_citizenship: Yup.string().required(
      'validations.user.place_of_citinzenship'
    ),
    address: Yup.object().shape({
      city: Yup.string().required('validations.adress.city'),
      house_number: Yup.string().required('validations.adress.house_number'),
      street: Yup.string().required('validations.adress.street'),
      zip_code: Yup.string().required('validations.adress.zip_code'),
    }),
  }),
  self: Yup.object().shape({
    first_name: Yup.string().required('validations.user.first_name'),
    last_name: Yup.string().required('validations.user.last_name'),
    birthday: Yup.date()
      .typeError('validations.invalid_date')
      .required('validations.user.date_of_birth.required')
      .max(
        new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
        'validations.user.date_of_birth.max'
      ),
    place_of_citizenship: Yup.string().required(
      'validations.user.place_of_citinzenship'
    ),
    address: Yup.object().shape({
      city: Yup.string().required('validations.adress.city'),
      house_number: Yup.string().required('validations.adress.house_number'),
      street: Yup.string().required('validations.adress.street'),
      zip_code: Yup.string().required('validations.adress.zip_code'),
    }),
  }),
});

export const stepTwoValidation = Yup.object().shape({
  meet_year: Yup.number()
    .typeError('validations.must_be_number')
    .required('validations.shouldNotBeEmpty'),
  date_of_moving_in: Yup.date()
    .typeError('validations.invalid_date')
    .required('validations.date_of_moving_in'),
  situation: Yup.string().required('validations.selectAnOption'),
  expenses_to_be_covered: Yup.array().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) => schema.min(1, 'validations.selectAnOption'),
  }),
  current_rental_agreement_name: Yup.string().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) =>
      schema.required('validations.current_rental_agreement_name'),
  }),
  joint_household_account_bank: Yup.string().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) => schema.required('validations.shouldNotBeEmpty'),
  }),
  equal_payment_amount: Yup.number().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) =>
      schema
        .typeError('validations.must_be_number')
        .required('validations.shouldNotBeEmpty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  equal_payment_frequency: Yup.string().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) => schema.required('validations.selectAnOption'),
  }),
  date_of_first_payment: Yup.date().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) =>
      schema
        .required('validations.selectAnOption')
        .typeError('validations.invalid_date'),
    otherwise: (schema) => schema.notRequired(),
  }),
  payment_ratio_per_party: Yup.number().when('situation', {
    is: (situation: string) =>
      situation === 'one_full_time_one_part_time_no_children',
    then: (schema) =>
      schema
        .typeError('validations.must_be_number')
        .min(0, 'validations.min_0')
        .max(100, 'validations.max_100')
        .required('validations.payment_ratio_per_party'),
    otherwise: (schema) => schema.notRequired(),
  }),
  compensation_share: Yup.number().when('situation', {
    is: (situation: string) =>
      situation !== 'both_employed_no_children' && situation !== '',
    then: (schema) =>
      schema
        .typeError('validations.must_be_number')
        .min(0, 'validations.min_0')
        .max(100, 'validations.max_100')
        .required('validations.payment_ratio_per_party'),
    otherwise: (schema) => schema.notRequired(),
  }),
  account_balance_in_case_of_separation: Yup.number().when('situation', {
    is: (situation: string) =>
      situation === 'one_full_time_one_part_time_no_children',
    then: (schema) =>
      schema
        .typeError('validations.must_be_number')
        .min(0, 'validations.min_0')
        .max(100, 'validations.max_100')
        .required('validations.shouldNotBeEmpty'),
    otherwise: (schema) => schema.notRequired(),
  }),

  workload_distribution: Yup.number().when('situation', {
    is: (situation: string) =>
      situation === 'one_full_time_one_part_time_no_children',
    then: (schema) =>
      schema
        .typeError('validations.must_be_number')
        .min(0, 'validations.min_0')
        .max(100, 'validations.max_100')
        .required('validations.shouldNotBeEmpty'),
    otherwise: (schema) => schema.notRequired(),
  }),
  who_works_full_time: Yup.string().when('situation', {
    is: (situation: string) =>
      situation === 'one_full_time_one_part_time_no_children',
    then: (schema) => schema.required('validations.selectAnOption'),
  }),
  household_and_children_responsibility: Yup.string().when('situation', {
    is: (situation: string) =>
      situation === 'one_full_time_one_household_with_children',
    then: (schema) => schema.required('validations.selectAnOption'),
  }),
  change_contract_plans: Yup.mixed().when('current_rental_agreement_name', {
    is: (current_rental_agreement_name: string) =>
      current_rental_agreement_name !== 'both',
    then: (schema) => schema.required('validations.selectAnOption'),
    otherwise: (schema) => schema.notRequired(),
  }),
  monthly_transfer_for_free_disposal: Yup.string().when('situation', {
    is: (situation: string) =>
      situation === 'one_full_time_one_household_with_children',
    then: (schema) => schema.required('validations.selectAnOption'),
  }),
  free_disposal_amount: Yup.number().when(
    ['monthly_transfer_for_free_disposal', 'situation'],
    {
      is: (monthly_transfer_for_free_disposal: string, situation: string) =>
        monthly_transfer_for_free_disposal === 'yes' &&
        situation === 'one_full_time_one_household_with_children',
      then: (schema) =>
        schema
          .typeError('validations.must_be_number')
          .required('validations.shouldNotBeEmpty'),
      otherwise: (schema) => schema.notRequired(),
    }
  ),
  compensation_payment_cadence: Yup.string().when('situation', {
    is: (situation: string) => situation !== '',
    then: (schema) => schema.required('validations.selectAnOption'),
    otherwise: (schema) => schema.notRequired(),
  }),
});
